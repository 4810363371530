import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import data from './data.json'

function App() {
  const [progressPercentage, setprogressPercentage] = useState(0)


  var i =0;

  function myLoop () {
    setTimeout(
      function(){
        setprogressPercentage(i);
        if (i<data.progress){
          ++i;
          myLoop();
        }
      },10
    )
  }

  useEffect(() => {
    myLoop();
  
  }, [])
  

  
  return (
    <div className="App">
      {/*<img id="logo" alt="urun_fotografi" src={require(`./logo.png`)} />*/}
      <img id="settings" alt="urun_fotografi" src={require(`./settings.png`)} />

      <h4 id="title">ÇOK YAKINDA HİZMETİNİZDE</h4>
      <section className='ilerleme'>
        <h5>Proje ilerleme durumu %{progressPercentage}</h5>
        <progress value={`${progressPercentage}`} max="100"></progress>
      </section>
    

    </div>
  );
}

export default App;
